import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { switchOrganization } from '../../../../actions/auth';
import { groupByCategory } from '../../../../constants/groupByCategoryFunction';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { Holding, OrgInfo } from '../../../../types/entities/holdings';
import formatNumber, { formatNumberToNdecimal } from '../../../../utils/formatNumber';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Button from '../../../ui/button/Button';
import Dot from '../../../ui/dot/Dot';
import Select from '../../../ui/formComponents/select/Select';
import LoaderCard from '../../../ui/loaders/loaderCard/LoaderCard';
import Table from '../../../ui/table/Table';
import './styles.scss';
import { getUniqueItemsByProperties } from '../../../../utils/removeDuplicates';
import useColumns from './hooks/useColumns';
import { Organization } from '../../../../types/entities/organization';

type Props = {
  data: Holding;
  loading: boolean;
  disabled: boolean;
  freeTrial: boolean;
};

function List({ data, loading, disabled, freeTrial }: Props) {
  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    id: 'all',
    name: t('dashboard.all')
  });

  const dispatch = useDispatch();

  const foundOrgHolding = useSelectedOrganization();

  const totalCo2Holding =
    data.parent_co2e.reduce((acc, elem) => acc + elem.total_emissions, 0) + data.childs_co2e;

  const parseOrg = (org: OrgInfo) => {
    let scope1 = 0;
    let scope2 = 0;
    let scope3 = 0;
    let totalCo2 = 0;

    const categories: Omit<CategoryData, 'scope'>[] = [];

    org.co2e.forEach((elem) => {
      if (elem.scope === 1) {
        scope1 += elem.total_emissions;
        totalCo2 += filter.id === 'all' || filter.id === '1' ? elem.total_emissions : 0;
      } else if (elem.scope === 2) {
        scope2 += elem.total_emissions;
        totalCo2 += filter.id === 'all' || filter.id === '2' ? elem.total_emissions : 0;
      } else if (elem.scope === 3) {
        scope3 += elem.total_emissions;
        totalCo2 += filter.id === 'all' || filter.id === '3' ? elem.total_emissions : 0;
      }

      const foundEmployeeCategory = categories.find(
        (category) => category.category === 'employees'
      );
      if (
        foundEmployeeCategory &&
        (elem.category === 'employees_in_labore' || elem.category === 'employees_in_itinere')
      ) {
        foundEmployeeCategory.total_emissions += elem.total_emissions;
      } else {
        categories.push({
          category: elem.category
            .replace(/_in_labore/g, '')
            .replace(/_in_itinere/, '')
            .replace('freight', 'transport')
            .replace('passenger', 'transport'),
          total_emissions: elem.total_emissions
        });
      }
    });
    const orgParsed: Record<string, any> = {
      company: (
        <div className='company'>
          <img src={org.holding ? '/images/icons/holding.svg' : '/images/icons/organization.svg'} />
          <span>{org.org_name}</span>
        </div>
      ),
      co2: (
        <span className='highlight-text-color tag-bigger-font' style={{ textAlign: 'right' }}>
          {formatNumberToNdecimal(totalCo2 / 1000)}
        </span>
      ),
      scope1: numberToDecimal(scope1 / 1000),
      scope2: numberToDecimal(scope2 / 1000),
      scope3: numberToDecimal(scope3 / 1000),
      percentage: totalCo2Holding
        ? `${formatNumberToNdecimal((totalCo2 / totalCo2Holding) * 100)}%`
        : '0.00%',
      goToCompany:
        foundOrgHolding && foundOrgHolding.role === 'owner' && !foundOrgHolding.is_group_fund ? (
          <div className='redirect'>
            <a href='' onClick={() => handleChangeOrganization(org.org_id)}>
              <img src='/images/icons/redirect.svg'></img>
            </a>
          </div>
        ) : null,
      id: org.org_id,
      holding: org.holding,
      totalCo2
    };

    categories.forEach(({ category, total_emissions }) => {
      if (!orgParsed[category]) {
        orgParsed[category] = numberToDecimal(total_emissions / 1000);
      } else {
        orgParsed[category] += numberToDecimal(total_emissions / 1000);
      }
    });

    return orgParsed;
  };

  const { columns, columns2, categoriesColumns } = useColumns({ filter });

  const parentOrgInfo: OrgInfo = {
    org_id: foundOrgHolding?.id ?? '',
    org_name: foundOrgHolding?.company_name ?? '',
    co2e: data.parent_co2e,
    remaining: 0,
    country: foundOrgHolding?.country ?? '',
    parent_org_name: '',
    parent_org_id: '',
    // data_quality: dataQualityPercentage,
    holding: true
  };

  const orgValues = getUniqueItemsByProperties(data.childs_info, ['org_id'])
    .map((org: OrgInfo) => {
      return parseOrg(org);
    })
    .sort((a: { totalCo2: number }, b: { totalCo2: number }) => b.totalCo2 - a.totalCo2);

  orgValues.unshift(parseOrg(parentOrgInfo));

  // Push a totals row to orgValues
  const totals: Record<string, any> = {
    company: (
      <div className='company'>
        <span className='weight-600'>{t('dashboard.total')}</span>
      </div>
    ),
    co2: (
      <span className='highlight-text-color tag-bigger-font'>
        {formatNumber(
          numberToDecimal(
            orgValues.reduce((acc: number, elem: any) => acc + elem.totalCo2, 0) / 1000
          )
        )}
      </span>
    ),
    scope1: (
      <span className='weight-600'>
        {numberToDecimal(orgValues.reduce((acc: number, elem: any) => acc + elem.scope1, 0))}
      </span>
    ),
    scope2: (
      <span className='weight-600'>
        {numberToDecimal(orgValues.reduce((acc: number, elem: any) => acc + elem.scope2, 0))}
      </span>
    ),
    scope3: (
      <span className='weight-600'>
        {numberToDecimal(orgValues.reduce((acc: number, elem: any) => acc + elem.scope3, 0))}
      </span>
    ),
    percentage: '100%',
    id: 'total'
  };

  const categoriesInTotals: string[] = [];
  // Add to totals all the categories
  groupByCategory()
    .filter((elem) => elem.category !== 'employees_in_labore')
    .forEach((elem) => {
      const categoryParsed = elem.category.replace(/_in_labore/g, '').replace(/_in_itinere/, '');
      totals[categoryParsed] = 0;
      categoriesInTotals.push(categoryParsed);
    });

  // Add to totals all the categories emissions in the orgValues accumulated
  orgValues.forEach((org: any) => {
    categoriesInTotals.forEach((elem) => {
      if (totals[elem] !== undefined && org[elem] !== undefined) {
        totals[elem] += org[elem];
      }
    });
  });

  if (loading) {
    return <LoaderCard />;
  }

  const handleChangeOrganization = (id: string) => {
    dispatch(switchOrganization(id));

    window.location.reload();
  };

  const onChangeFilter = (value: SelectOptionFormat) => {
    setFilter(value);
  };

  return (
    <div className='card dashboard-card' style={{ gridColumn: '1 / -1' }}>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>
          {t('dashboard.impactByOrganization')}
        </h4>
        {freeTrial ? (
          <Button
            lookAndFeel='blocked'
            size='tiny'
            text={t('blockedButton.improvePlan')}
            onClick={() => {
              console.log('');
            }}
          />
        ) : (
          <div style={{ width: '10rem' }}>
            <Select
              icon='/images/icons/filter.svg'
              placeholder={'select'}
              height='24px'
              size='small'
              options={[
                {
                  id: 'all',
                  name: t('dashboard.all')
                },
                {
                  id: '1',
                  name: t('dashboard.scope1')
                },
                {
                  id: '2',
                  name: t('dashboard.scope2')
                },
                {
                  id: '3',
                  name: t('dashboard.scope3')
                }
              ]}
              value={filter}
              onChangeValue={onChangeFilter}
            />
          </div>
        )}
      </div>
      <div className='list-wrapper-orgs'>
        <Table
          columns={filter.id === 'all' ? columns : columns2}
          data={freeTrial ? [] : orgValues}
          loading={false}
          size={'small'}
          summary={(data: any) => {
            const columnsToUse = filter.id === 'all' ? columns : columns2;
            return (
              <tr>
                {columnsToUse.map((column) => {
                  const textAlign = column.align === 'right' ? 'right' : 'left';
                  return (
                    <td key={column.key} style={{ textAlign }}>
                      {totals[column.key]}
                    </td>
                  );
                })}
              </tr>
            );
          }}
        />
      </div>
    </div>
  );
}

export default List;
